import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Products } from './../products/products';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  url = '../../assets/data.json';

  constructor(private http: HttpClient) {}

  getProducts() {
    return this.http.get(this.url);
  }
}
