
import { Router, RouterStateSnapshot, RouterState, ActivatedRouteSnapshot } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ProductService } from './../shared/product.service';


@Component({
  selector: 'anms-prodduct-details',
  templateUrl: './prodduct-details.component.html',
  styleUrls: ['./prodduct-details.component.css']
})
export class ProdductDetailsComponent implements OnInit {

  routeLocation: any = '';
  locationId: any;
  currentProduct: any[] = [];
  allProducts: any;
  products: Object;

  constructor(router: Router, private pdtService: ProductService) {
    const state: RouterState = router.routerState;
    const snapshot: RouterStateSnapshot = state.snapshot;
    const root: ActivatedRouteSnapshot = snapshot.root;
    const child = root.firstChild;
    // const id: Observable<string> = child.params.map(p => p.id);

    this.routeLocation = snapshot.url;
    console.log(snapshot);
  }

  ngOnInit() {
    this.getProductId();
    this.getProductById(this.locationId);
  }
  getProductId(){
    this.locationId = this.routeLocation.split('/').pop();
    console.log(this.locationId);
  }

  getProductById(id){
    // not the best implementation here, but works for this small app
      this.pdtService.getProducts().subscribe( products => {
      this.allProducts = JSON.stringify(products);
        console.log(this.allProducts);
        this.products = products;
        console.log(products[ (this.locationId) ].product_desc);
        console.log('one prod' + products[2].product_desc);

      // console.log('Product Number ' + this.locationId + ' Details');
      // console.log(this.allProducts[this.locationId].product_price );
      // // console.log(products[0]);
      // id = this.locationId;
      // this.currentProduct = this.allProducts[0][id];
      // console.log(this.currentProduct)

    });
    // return this.currentProduct;
  }
}
