import { ProductService } from './../shared/product.service';
import { Component, OnInit } from '@angular/core';


import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';

@Component({
  selector: 'anms-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  theProducts: Object;
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;

  constructor(public productService: ProductService) {}

  ngOnInit() {
    this.productService.getProducts().subscribe(products => {
      console.log(products);
      this.theProducts = products;
    });
  }

}
